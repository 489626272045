<template>
  <v-footer padless app absolute>
    <v-card class="flex" flat tile color="secondary">
      <v-card-text class="py-2 text-right">
        {{ new Date().getFullYear() }} - STEPP v{{ version }}
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
    };
  },
};
</script>
