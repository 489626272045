<template>
  <v-app>
    <Navbar />
    <v-main>
      <Alert />
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Alert from "./components/Alert.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Navbar,
    Alert,
    Footer,
  },

  created() {
    if (localStorage.themeStepp) {
      this.$vuetify.theme.dark = localStorage.themeStepp === "dark";
    } else if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.$vuetify.theme.dark = true;
    }
  },
};
</script>
