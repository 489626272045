<template>
  <v-container>
    <v-card class="mx-auto" max-width="1200px">
      <v-tabs vertical>
        <v-tab>
          <v-icon left>mdi-teach</v-icon>Teachers
          <v-spacer></v-spacer>
        </v-tab>
        <!-- <v-tab>
          <v-icon left>mdi-file-document-edit-outline</v-icon>Documents
          <v-spacer></v-spacer>
        </v-tab> -->

        <v-tab-item>
          <TeacherTab />
        </v-tab-item>
        <v-tab-item>
          <DocumentTab />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
import TeacherTab from "./TeacherTab.vue";
// import DocumentTab from "./DocumentTab.vue";

export default {
  components: {
    TeacherTab,
    // DocumentTab,
  },
};
</script>
