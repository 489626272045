<template>
  <v-container>
    <v-card class="mx-auto" max-width="1200px">
      <v-tabs vertical>
        <v-tab>
          <v-icon left>mdi-youtube</v-icon>
          Videos
          <v-spacer></v-spacer>
        </v-tab>

        <v-tab>
          <v-icon left>mdi-file-pdf-outline</v-icon>
          Manuals
          <v-spacer></v-spacer>
        </v-tab>

        <v-tab-item>
          <v-card class="mx-auto" flat>
            <v-card color="primary" class="mx-auto white--text">
              <v-card-title>Videos</v-card-title>
            </v-card>

            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Module</th>
                      <th class="text-left">Description</th>
                      <th class="text-left">Link</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(video, index) in videos" :key="index">
                      <td>{{ video.name }}</td>
                      <td>{{ video.description }}</td>
                      <td>
                        <a v-if="video.link" :href="video.link" target="_blank">
                          Click here
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card class="mx-auto" flat>
            <v-card color="primary" class="mx-auto white--text">
              <v-card-title>Manuals</v-card-title>
            </v-card>

            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Manual Name</th>
                      <th class="text-left">Description</th>
                      <th class="text-left">File</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(manual, index) in manuals" :key="index">
                      <td>{{ manual.name }}</td>
                      <td>{{ manual.description }}</td>
                      <td>
                        <a
                          :href="`${publicPath}files/${manual.file}`"
                          target="_blank"
                        >
                          {{ manual.file }}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      videos: [
        {
          name: "How to Upload your Module 0 Concept Map",
          description:
            "Tutorial video on how to upload a file to an assignment",
          link: "https://youtu.be/zntcVWL6tcY",
        },
        {
          name: "Module 1",
          description: "Level 1",
          link: "https://youtu.be/5cVXG54rZ8k",
        },
        {
          name: "Module 1",
          description: "Level 2",
          link: "https://youtu.be/jRRZ-q-FQyQ",
        },
        {
          name: "Module 1",
          description: "Level 3",
          link: "https://youtu.be/LwsMDTNyQq8",
        },
        {
          name: "Module 1",
          description: "Level 4",
          link: "https://youtu.be/Dy2y9HpoNSM",
        },

        { name: "", description: "", link: null },
        {
          name: "Module 2",
          description: "Level 1",
          link: "https://youtu.be/nxZrC9FLC5w",
        },
        {
          name: "Module 2",
          description: "Level 2",
          link: "https://youtu.be/quBCQvOY7j4",
        },
        {
          name: "Module 2",
          description: "Level 3",
          link: "https://youtu.be/5ftW5WFjCWM",
        },
        {
          name: "Module 2",
          description: "Level 4",
          link: "https://youtu.be/04VxSepr428",
        },
        {
          name: "Module 2",
          description: "Level 5",
          link: "https://youtu.be/j90-xnS_7w4",
        },
        {
          name: "Module 2",
          description: "Level 6",
          link: "https://youtu.be/6G0wgjbLM30",
        },

        { name: "", description: "", link: null },
        {
          name: "Module 3",
          description: "Level 1",
          link: "https://youtu.be/SFvxGHNrgKA",
        },
        {
          name: "Module 3",
          description: "Level 2",
          link: "https://youtu.be/NzVmytIh1gM",
        },
        {
          name: "Module 3",
          description: "Level 3",
          link: "https://youtu.be/DPl2XAKkQuU",
        },
        {
          name: "Module 3",
          description: "Level 4",
          link: "https://youtu.be/0tYh6ApaLY8",
        },
        {
          name: "Module 3",
          description: "Level 5",
          link: "https://youtu.be/miFHXgXbm7E",
        },
      ],
      manuals: [
        {
          description: "A guide on how to submit a file to an assignment.",
          name: "Submitting an Assignment",
          file: "User Guide_Submitting a File.pdf",
        },
        {
          description:
            "A guide on the basic functions and navigation of STEPP.",
          name: "STEPP Basics",
          file: "User Guide_STEPP Basics.pdf",
        },
        {
          description:
            "A cheat sheet of various basic functions and inputs required in each level of STEPP.",
          name: "STEPP Cheatsheet",
          file: "Student Cheatsheets.pdf",
        },
        {
          description:
            "A user guide on the various levels of Module 1 focusing on what's new in each level.",
          name: "Module 1 User Guide",
          file: "User Guide_Module 1.pdf",
        },
        {
          description:
            "A guide on each level of Module 2 focusing on what's new in each level.",
          name: "Module 2 User Guide",
          file: "User Guide_Module 2.pdf",
        },
        {
          description:
            "This is a user guide on new features in Module 3 Levels 1 and 2.",
          name: "Module 3 Levels 1 and 2 User Guide",
          file: "User Guide_Module 3 Levels 1 and 2.pdf",
        },
        {
          description: "User Guides on Levels 3, 4, and 5 of Module 3",
          name: "Module 3 Levels 3-5 User Guide",
          file: "User Guide_Module 3 Levels 3 to 5.pdf",
        },
      ],
    };
  },
};
</script>
