<template>
  <v-container>
    <v-card
      class="mx-auto mb-5"
      max-width="1200px"
      v-for="(item, index) in items"
      :key="index"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="headline">
            {{ item.title }}
          </v-list-item-title>

          <v-list-item-subtitle>Date: {{ item.date }}</v-list-item-subtitle>

          <v-list-item-subtitle>
            Status: {{ item.status }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-img
        v-if="item.title == 'SIGSIM'"
        :src="`${publicPath}images/SIGSIM.png`"
        max-width="500px"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate></v-progress-circular>
          </v-row>
        </template>
      </v-img>

      <v-img
        v-else-if="
          item.title != 'Integrating Computational Thinking Conference'
        "
        :src="`${publicPath}images/${item.title}.png`"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate></v-progress-circular>
          </v-row>
        </template>
      </v-img>

      <v-card-text class="text--primary">
        {{ item.content }}
      </v-card-text>

      <v-card-actions>
        <v-btn
          v-if="item.paper"
          :href="item.paper"
          target="_blank"
          color="primary"
        >
          Read paper
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      items: [
        {
          title: "SIGSIM",
          date: "June 3-5, 2019",
          status: "PRESENTED",
          content:
            "The 2019 ACM SIGSIM Conference on Principles of Advanced Discrete Simulation (ACM SIGSIM PADS) will take place at the Illinois Institute of Technology (IIT), on June 3-5, 2019 in Chicago, Illinois, USA. The annual PADS conference has a long history dating back to 1985. The conference was formerly known under the name Principles of Advanced and Distributed Simulation, and before that simply Parallel and Distributed Simulation. Over the years PADS has broadened its scope beyond its origins in parallel and distributed simulation and now encompasses virtually all research that lies at the intersection of the computer science and the modeling and simulation fields. Specifically, many research topics not related to parallel or distributed model execution are now included. SIGSIM PADS provides a unique forum for reporting and discussing research results and important topics of interest to the M&S community. SIGSIM PADS is the flagship conference of ACM Special Interest Group on Simulation and Modeling (SIGSIM) and is fully sponsored by that organization.",
          paper:
            "https://dl.acm.org/ft_gateway.cfm?id=3325513&ftid=2064061&dwn=1&CFID=156145391&CFTOKEN=b27d7e72d71f6aed-B849C64A-A1EA-895A-AAD5FBDA033121E4",
        },
        {
          title: "Integrating Computational Thinking Conference",
          date: "May 2-5, 2019",
          status: "PRESENTED",
          content: "Poster presented at College Park, MD.",
        },
        {
          title: "UTeach",
          date: "June 2, 2018",
          status: "PRESENTED",
          content:
            "The UTeach Institute provides guidance and resources to ensure successful implementation and sustainability of UTeach programs nationwide. UTeach is an innovative university-based teacher preparation program working to increase the number of qualified science, technology, engineering, and mathematics (STEM) teachers in U.S. secondary schools. The program combines rigorous STEM degrees with secondary teaching certification without adding time or cost to four-year degrees.",
        },
        {
          title: "STEPP",
          date: "June 2, 2018",
          status: "PRESENTED",
          content:
            "The study, Scaffolded Training Environment for Physics Programming (STEPP Project), tests a novel approach for teaching physics to high school students. This three-year research project uses specialized computer programs to teach students physics. Part of research is the development of a programming platform that allows a student with little or no knowledge of computer coding to build a system of rules and procedures from the ground up.",
        },
      ],
    };
  },
};
</script>
