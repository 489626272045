<template>
  <v-main>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card>
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>Forgot password?</v-toolbar-title>
            </v-toolbar>

            <v-card-text>
              {{
                !user
                  ? "Enter your registered email for password reset instruction."
                  : ""
              }}
              <v-form v-model="formIsValid">
                <v-text-field
                  prepend-icon="mdi-email-outline"
                  label="Email"
                  name="email"
                  v-model="email"
                  :disabled="Boolean(user)"
                  :rules="[rule.required, rule.email]"
                  required
                ></v-text-field>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <p v-if="!user" class="ml-3">
                Have password?
                <router-link
                  to="/signin"
                  class="font-weight-bold"
                  style="text-decoration: none"
                >
                  Sign in
                </router-link>
              </p>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="loading || !formIsValid"
                :loading="loading"
                @click="reset"
                color="primary"
              >
                Reset password
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { auth } from "../api/firebase";
import { sendPasswordResetEmail } from "firebase/auth";

export default {
  data() {
    return {
      user: auth.currentUser,
      rule: {
        required: (v) => !!v || "Required",
        email: (v) =>
          /^[\w-.]+@([\w-]+\.)+[\w-]{2,7}$/.test(v) || "E-mail must be valid",
      },

      email: "",
      message: "",

      loading: false,
      formIsValid: true,
    };
  },

  created() {
    if (this.user) {
      this.email = this.user.email;
    }
  },

  methods: {
    async reset() {
      this.loading = true;
      try {
        await sendPasswordResetEmail(auth, this.email);
        this.$toast.success("Password reset email sent");
      } catch (error) {
        this.$toast.error("Something went wrong, please try again later");
      }
      this.loading = false;
    },
  },
};
</script>
