<template>
  <v-app-bar app dense flat color="secondary">
    <router-link to="/">
      <v-img
        :src="require('../assets/stepp_logo_w.png')"
        contain
        class="mx-3"
        max-height="35"
        max-width="35"
      >
        <template v-slot:placeholder>
          <v-row class="ma-1" align="center" justify="center">
            <v-progress-circular indeterminate></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </router-link>

    <v-toolbar-title class="headline text-uppercase">
      <router-link text to="/" style="text-decoration: none; color: inherit">
        <div class="d-inline">STEPP</div>
      </router-link>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-toolbar-items class="hidden-sm-and-down">
      <v-tabs class="hidden-sm-and-down" hide-slider>
        <v-tab v-for="item in updatedMenu" :key="item.icon" :to="item.link">
          <v-icon size="17" left>mdi-{{ item.icon }}</v-icon>
          <div class="tab-width-2">
            {{ item.title }}
          </div>
        </v-tab>

        <v-tab v-if="user" @click="signout">
          <v-icon size="17" left>mdi-logout</v-icon>
          <div class="tab-width-2">Sign Out</div>
        </v-tab>
      </v-tabs>
    </v-toolbar-items>

    <v-menu>
      <template v-slot:activator="{ on: menu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn icon v-on="{ ...tooltip, ...menu }">
              <v-icon size="18" style="transform: rotate(-45deg)">
                mdi-moon-waning-crescent
              </v-icon>
            </v-btn>
          </template>
          <span>Change Theme</span>
        </v-tooltip>
      </template>

      <v-list rounded>
        <v-list-item-group v-model="themeSelected">
          <v-list-item
            v-for="(item, index) in themeMenu"
            :key="index"
            @click="switchTheme(item)"
          >
            <v-list-item-title>{{ item }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>

    <v-menu>
      <template v-slot:activator="{ on }">
        <v-app-bar-nav-icon
          class="hidden-md-and-up"
          v-on="on"
        ></v-app-bar-nav-icon>
      </template>

      <v-list rounded>
        <v-list-item
          v-for="(item, index) in updatedMenu"
          :key="index"
          :to="item.link"
        >
          <v-icon left size="18">mdi-{{ item.icon }}</v-icon>
          {{ item.title }}
        </v-list-item>

        <v-list-item v-if="user" @click="signout">
          <v-icon left size="18">mdi-logout</v-icon>Sign Out
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { auth, db } from "../api/firebase";
import { signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

export default {
  data() {
    return {
      user: auth.currentUser,
      userData: null,
      menu: [
        {
          icon: "home",
          title: "Home",
          link: "/",
        },
        {
          icon: "account-multiple",
          title: "Team",
          link: "/team",
        },
        {
          icon: "newspaper-variant",
          title: "Publication",
          link: "/publication",
        },
        {
          icon: "notebook",
          title: "Tutorial",
          link: "/tutorial",
        },
        {
          icon: "play-circle",
          title: "Play",
          link: "/application",
        },
        {
          icon: "account-circle",
          title: "Account",
          link: "/account",
          require: "auth",
        },
        {
          icon: "view-dashboard",
          title: "Dashboard",
          link: "/admin",
          require: "admin",
        },
        {
          icon: "login",
          title: "Sign in",
          link: "/signin",
          require: "guest",
        },
      ],
      themeMenu: ["System Default", "Dark", "Light"],
      themeSelected: "",
    };
  },

  async created() {
    if (this.user) {
      const userDoc = await getDoc(doc(db, "users", this.user.uid));
      if (userDoc.exists()) {
        this.userData = userDoc.data();
      }
    }
    if (localStorage.themeStepp) {
      if (localStorage.themeStepp === "dark") {
        this.themeSelected = 1;
      } else {
        this.themeSelected = 2;
      }
    } else {
      this.themeSelected = 0;
    }
  },

  watch: {
    $route() {
      this.user = auth.currentUser;
    },
  },

  computed: {
    updatedMenu() {
      if (this.user) {
        if (this.isAdmin) {
          return this.menu.filter(
            (item) =>
              !item.require ||
              item.require === "auth" ||
              item.require === "admin"
          );
        } else if (this.userData && this.userData.isApproved) {
          return this.menu.filter(
            (item) =>
              !item.require ||
              item.require === "auth" ||
              item.require === "approval"
          );
        }
        return this.menu.filter(
          (item) => !item.require || item.require === "auth"
        );
      } else {
        return this.menu.filter(
          (item) => !item.require || item.require === "guest"
        );
      }
    },

    isAdmin() {
      return this.user.uid === "WnTFxezsfUh6LwLDcMijUEfliIz2";
    },
  },

  methods: {
    async signout() {
      await signOut(auth);
      this.$router.push("signin");
    },
    switchTheme(theme) {
      if (theme === "System Default") {
        localStorage.removeItem("themeStepp");
        if (
          window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches
        ) {
          this.$vuetify.theme.dark = true;
        }
      } else if (theme === "Light") {
        localStorage.themeStepp = "light";
        this.$vuetify.theme.dark = false;
      } else {
        localStorage.themeStepp = "dark";
        this.$vuetify.theme.dark = true;
      }
    },
  },
};
</script>

<style>
.v-tabs-bar {
  background-color: inherit !important;
}
</style>
