<template>
  <v-main>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card>
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>Sign in as teacher</v-toolbar-title>
            </v-toolbar>

            <v-card-text>
              <v-form ref="form" v-model="formIsValid">
                <v-text-field
                  prepend-icon="mdi-email-outline"
                  label="Email"
                  name="email"
                  v-model="email"
                  :rules="[rule.required, rule.email]"
                  required
                ></v-text-field>

                <v-text-field
                  prepend-icon="mdi-lock-open-outline"
                  label="Password"
                  name="password"
                  v-model="password"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="!showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  :rules="[rule.required]"
                  required
                ></v-text-field>

                <p>
                  <router-link
                    to="/forgot-password"
                    class="font-weight-bold"
                    style="text-decoration: none"
                  >
                    Forgot password?
                  </router-link>
                </p>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <p class="ml-3">
                Don't have an account?
                <router-link
                  to="/signup"
                  class="font-weight-bold"
                  style="text-decoration: none"
                >
                  Sign up
                </router-link>
              </p>
              <v-spacer></v-spacer>
              <v-btn :disabled="!formIsValid" @click="signin" color="primary">
                <v-icon left>mdi-login</v-icon>Sign in
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { auth } from "../api/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";

export default {
  data() {
    return {
      rule: {
        required: (v) => !!v || "Required",
        email: (v) =>
          /^[\w-.]+@([\w-]+\.)+[\w-]{2,7}$/.test(v) || "E-mail must be valid",
      },
      email: "",
      password: "",
      formIsValid: true,
      showPassword: false,
    };
  },

  methods: {
    async signin() {
      try {
        await signInWithEmailAndPassword(auth, this.email, this.password);
        this.$router.push("/");
      } catch (error) {
        this.$toast.error("Wrong email or password");
      }
    },
  },
};
</script>
