<template>
  <v-container>
    <v-card class="mx-auto" max-width="1200px">
      <v-list two-line>
        <template v-for="(item, index) in items">
          <v-subheader v-if="item.header" :key="index" inset>
            {{ item.header }}
          </v-subheader>

          <v-divider v-else-if="item.divider" :key="index" inset></v-divider>

          <v-list-item v-else :key="index">
            <v-list-item-avatar size="84">
              <v-img
                :src="`${publicPath}images/${
                  item.noImage ? 'None' : item.name.split(' ')[0]
                }.jpg`"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular indeterminate></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-html="item.name"></v-list-item-title>
              <v-list-item-subtitle v-html="item.title"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      items: [
        {
          header: "Principal Investigators (PI)",
        },
        { divider: true },
        {
          name: "Midori Kitagawa",
          title:
            "Research Professor - School of Natural Sciences and Mathematics, UT Dallas",
        },
        {
          name: "Paul Fishwick",
          title:
            "Professor - School of ATEC and the Department of Computer Science, UT Dallas",
        },
        {
          name: "Michael Kesden",
          title: "Associate Professor in the Department of Physics, UT Dallas",
        },
        {
          name: "Mary L. Urquhart",
          title:
            "Associate Professor and Head of the Department of Science/Mathematics Education and Affiliate Associate Professor of Physics, UT Dallas",
        },
        {
          name: "Rosanna E. Guadagno",
          title:
            "Director, Information Warfare Working Group, (CISAC), Stanford University",
        },

        {
          header: "Subject Matter Experts",
        },
        { divider: true },
        {
          name: "Henry Vo",
          title: "Director of Computer Science, Richardson High School",
        },

        {
          header: "NSF Research Experiences for Teaches Fellows",
        },
        { divider: true },
        {
          name: "Rachel Hagedorn",
          title: "Physics Teacher - Newman Smith High School",
        },
        {
          name: "Wesley Baker",
          title:
            "Head of Science Department and Physics Teacher - Creekview High School",
        },

        {
          header: "STEPP Focus Group Members",
        },
        { divider: true },
        {
          name: "Sara Holloway",
          title: "Physics Teacher - Newman Smith High School",
        },
        {
          name: "Nick Shovlin",
          title: "Physics Teacher - Berkner High School",
          noImage: true,
        },
        {
          name: "Baily Hale",
          title: "Physics Teacher - Newman Smith High School",
        },

        {
          header: "Research Assistants - Curriculum Team",
        },
        { divider: true },
        {
          name: "Ken Suura - Project Manager",
          title: "Graduate Student - Physics, Science & Math Education",
        },

        {
          header: "Research Assistants - Assessment team",
        },
        { divider: true },
        {
          name: "Haley Puddy",
          title: "Graduate Student - Economic, Political and Policy Sciences",
        },

        {
          header: "Research Assistants - Tech Team",
        },
        { divider: true },
        {
          name: "Mike Tran - Research Assistant, Tech Team Lead",
          title: "PhD Student - Computer Science",
        },
        {
          name: "Lan Vu - Research Assistant, Web Developer",
          title: "Undergraduate Student - Computer Science",
        },
        {
          name: "Duong Nguyen - Research Assistant, Web Developer",
          title: "Undergraduate Student - Computer Science",
        },
        {
          name: "Pranay Mantramurti - Research Assistant, Unity Developer",
          title: "Undergraduate Student - Computer Science",
        },
        {
          name: "Alex Najera - Research Assistant, Unity Developer",
          title: "Undergraduate Student - Computer Science",
        },
        {
          name: "Diego Ochoa - Research Assistant, Unity Developer",
          title: "Undergraduate Student - Computer Engineer",
        },
        {
          name: "Khoi Tran - Research Assistant, Unity Developer",
          title: "Undergraduate Student - Computer Science",
        },

        { divider: true },
        {
          header: "Former members of STEPP",
        },
        { divider: true },
        {
          name: "Virgil Gonzenbach",
          title: "Graduate Student - Behavioral and Brain Sciences",
        },
        {
          name: "Siddhesh Walunj - Research Assistant, Unity Developer",
          title: "Graduate Student - Information Technology and Management",
        },
        {
          name: "Prashuk Ajmera - Research Assistant, Web Developer",
          title: "Graduate Student - Computer Science",
        },
        {
          name: "Arihant Chhajed - Research Assistant, Web Developer",
          title: "Graduate Student - Computer Science",
        },
        {
          name: "Praneeth Baru - Research Assistant, Unity Developer",
          title: "Graduate Student - Computer Science",
        },
        {
          name: "Priyanka Awaraddi - Research Assistant, Web Developer",
          title: "Graduate Student - Computer Science",
        },
        {
          name: "Rong Jin - Research Assistant, Unity Developer",
          title: "PhD Student - Computer Science",
        },
        {
          name: "Cenk Köknar - Research Assistant, Unity Developer",
          title: "PhD Candidate - Arts, Technology, and Emerging Communication",
        },
        {
          name: "Erik Omogbehin - Research Assistant, Unity Developer",
          title: "Undergraduate Student - Computer Science",
        },
        {
          name: "Aditya Prakash - Research Assistant, Unity Developer",
          title: "Graduate Student - Computer Science",
        },
        {
          name: "Aniket Raj - Research Assistant, Unity Developer",
          title: "Graduate Student, Lone Star College",
        },
        {
          name: "James Stanfield - Subject Matter Expert",
          title: "Physics Teacher, Richardson High School",
        },
      ],
    };
  },
};
</script>
