import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { auth } from "./api/firebase";
import { onAuthStateChanged } from "firebase/auth";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const options = {
  position: "bottom-right",
};

Vue.use(Toast, options);

Vue.config.productionTip = false;

let app;
onAuthStateChanged(auth, () => {
  if (!app) {
    app = new Vue({
      router,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
