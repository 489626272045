<template>
  <div>
    <v-card class="mx-auto" max-width="1200px" flat>
      <v-card color="primary" class="mx-auto white--text">
        <v-card-title>Manage Teacher Access</v-card-title>
      </v-card>

      <v-tabs>
        <v-tab>Approved teachers</v-tab>
        <v-tab>
          <v-badge
            color="red"
            :content="pendingTeachers.length"
            :value="pendingTeachers.length"
          >
            Pending Requests
          </v-badge>
        </v-tab>
        <v-tab>Denied list</v-tab>

        <v-tab-item>
          <v-card>
            <v-card-title>
              Approved Teachers
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchTeacher"
                label="Search"
                append-icon="mdi-magnify"
                hide-details
                clearable
                outlined
                dense
                solo
                flat
              ></v-text-field>

              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2"
                    @click="getTeachers()"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>
            </v-card-title>

            <v-card-text>
              <v-data-table
                :headers="headersTeacher"
                :items="approvedTeachers"
                :search="searchTeacher"
                :sort-by="['fullName']"
                item-key="id"
                :items-per-page="-1"
              >
                <template #item.isApproved="{ item }">
                  <v-chip
                    v-if="item.isApproved"
                    :color="getColor(item.isApproved)"
                    class="white--text"
                  >
                    Yes
                  </v-chip>
                  <v-chip
                    v-else
                    :color="getColor(item.isApproved)"
                    class="white--text"
                  >
                    No
                  </v-chip>
                </template>

                <template #item.actions="{ item }">
                  <v-btn
                    color="error"
                    text
                    @click="deleteTeacherDialogShow(item)"
                  >
                    Remove access
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card>
            <v-card-title>
              Pending Requests
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchRequest"
                label="Search"
                append-icon="mdi-magnify"
                hide-details
                clearable
                outlined
                dense
                solo
                flat
              ></v-text-field>

              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2"
                    @click="getTeachers()"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>
            </v-card-title>

            <v-card-text>
              <v-data-table
                :headers="headersTeacher"
                :items="pendingTeachers"
                :search="searchRequest"
                :sort-by="['fullName']"
                item-key="id"
                :items-per-page="-1"
              >
                <template #item.actions="{ item }">
                  <v-btn
                    text
                    color="primary"
                    @click="acceptTeacherDialogShow(item)"
                  >
                    Approve
                  </v-btn>
                  <v-btn
                    color="error"
                    text
                    @click="deleteTeacherDialogShow(item)"
                  >
                    Deny
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card>
            <v-card-title>
              Denied List
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchDenied"
                label="Search"
                append-icon="mdi-magnify"
                hide-details
                clearable
                outlined
                dense
                solo
                flat
              ></v-text-field>

              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2"
                    @click="getTeachers()"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>
            </v-card-title>

            <v-card-text>
              <v-data-table
                :headers="headersTeacher"
                :items="deniedTeachers"
                :search="searchDenied"
                :sort-by="['fullName']"
                item-key="id"
                :items-per-page="-1"
              >
                <template #item.actions="{ item }">
                  <v-btn
                    text
                    color="primary"
                    @click="acceptTeacherDialogShow(item)"
                  >
                    Restore
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>

    <v-dialog v-model="teacherAcceptDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span v-if="acceptForm.isApproved === false" class="headline">
            Approve {{ acceptForm.fullName }} request?
          </span>
          <span v-else class="headline">
            Restore {{ acceptForm.fullName }}'s access?
          </span>
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="teacherAcceptDialog = false">
            Cancel
          </v-btn>
          <v-btn
            :loading="loadingAccept"
            :disabled="loadingAccept"
            class="ml-1"
            color="primary"
            text
            @click="acceptTeacher"
          >
            {{ acceptForm.isApproved === false ? "Approve" : "Restore" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="teacherDeleteDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span v-if="deleteForm.isApproved === false" class="headline">
            Deny {{ deleteForm.fullName }} request?
          </span>
          <span v-else class="headline">
            Remove {{ deleteForm.fullName }}'s access?
          </span>
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="teacherDeleteDialog = false">
            Cancel
          </v-btn>
          <v-btn
            :loading="loadingDelete"
            :disabled="loadingDelete"
            class="ml-1"
            color="error"
            text
            @click="deleteTeacher"
          >
            {{ deleteForm.isApproved === false ? "Deny" : "Remove" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { auth, db } from "../../../api/firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";

export default {
  data() {
    return {
      user: auth.currentUser,
      acceptForm: {
        id: null,
        fullName: "",
        isApproved: undefined,
      },
      deleteForm: {
        id: null,
        fullName: "",
        isApproved: undefined,
      },
      headersTeacher: [
        { text: "Name", value: "fullName" },
        { text: "Email", value: "email" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      teachers: [],
      searchTeacher: "",
      searchRequest: "",
      searchDenied: "",

      loadingAccept: false,
      teacherAcceptDialog: false,
      loadingDelete: false,
      teacherDeleteDialog: false,
    };
  },

  created() {
    this.getTeachers();
  },

  computed: {
    approvedTeachers() {
      return this.teachers.filter((teacher) => teacher.isApproved === true);
    },
    pendingTeachers() {
      return this.teachers.filter((teacher) => teacher.isApproved === false);
    },
    deniedTeachers() {
      return this.teachers.filter((teacher) => teacher.isApproved === null);
    },
  },

  methods: {
    async getTeachers() {
      const q = query(
        collection(db, "users"),
        where("isEmailVerified", "==", true)
      );
      const teacherDocs = await getDocs(q);
      this.teachers = [];
      teacherDocs.forEach((doc) => {
        if (doc.id !== "WnTFxezsfUh6LwLDcMijUEfliIz2") {
          const teacher = {
            id: doc.id,
            firstName: doc.data().firstName,
            lastName: doc.data().lastName,
            fullName: doc.data().firstName + " " + doc.data().lastName,
            email: doc.data().email,
            isApproved: doc.data().isApproved,
            isEmailVerified: doc.data().isEmailVerified,
          };
          this.teachers.push(teacher);
        }
      });
    },

    acceptTeacherDialogShow(item) {
      this.teacherAcceptDialog = true;
      this.acceptForm = item;
    },

    async acceptTeacher() {
      this.loadingAccept = true;
      try {
        await updateDoc(doc(db, "users", this.acceptForm.id), {
          isApproved: true,
        });
        this.loadingAccept = false;
        this.$toast.success(
          `${this.acceptForm.fullName} is added to Approved list`
        );
        this.teacherAcceptDialog = false;
        this.getTeachers();
      } catch (error) {
        this.loadingAccept = false;
        this.$toast.error("Something went wrong, please try again later");
      }
    },

    deleteTeacherDialogShow(item) {
      this.teacherDeleteDialog = true;
      this.deleteForm = item;
    },

    async deleteTeacher() {
      this.loadingDelete = true;
      try {
        await updateDoc(doc(db, "users", this.deleteForm.id), {
          isApproved: null,
        });
        this.loadingDelete = false;
        this.$toast.success(
          `${this.deleteForm.fullName} is added to Denied list`
        );
        this.teacherDeleteDialog = false;
        this.getTeachers();
      } catch (error) {
        this.loadingDelete = false;
        this.$toast.error("Something went wrong, please try again later");
      }
    },
  },
};
</script>
