<template>
  <div v-if="!isAdmin">
    <v-alert
      v-if="user && !user.emailVerified"
      class="ma-0"
      type="warning"
      icon="mdi-alert-circle"
      dense
      tile
    >
      Please verify your email <b>BEFORE</b> requesting access. Didn't see
      verification email?
      <v-btn
        :loading="resendLoading"
        @click="resendVerification"
        small
        outlined
      >
        <v-icon left>mdi-email</v-icon>
        Resend
      </v-btn>
    </v-alert>
    <v-alert
      v-if="user && !isApproved"
      type="error"
      icon="mdi-alert"
      dense
      tile
    >
      Access not granted, please request access to upload documents.
      {{
        user && !user.emailVerified
          ? "Can't click button? Please verify your email first."
          : ""
      }}
      <v-btn
        :loading="requestLoading"
        @click="requestAccess"
        :disabled="(user && !user.emailVerified) || isEmailVerified"
        small
        outlined
      >
        <v-icon left>mdi-key</v-icon>
        Request access
      </v-btn>
      <span class="text-subtitle-2">
        {{ isEmailVerified ? "request sent" : "" }}
      </span>
    </v-alert>
  </div>
</template>

<script>
import { auth, db } from "../api/firebase";
import { sendEmailVerification } from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";

export default {
  data() {
    return {
      user: auth.currentUser,
      isApproved: true,
      isEmailVerified: false,
      resendLoading: false,
      requestLoading: false,
    };
  },

  created() {
    this.getUserData();
  },

  watch: {
    $route() {
      this.user = auth.currentUser;
      this.getUserData();
    },
  },

  computed: {
    isAdmin() {
      return this.user && this.user.uid === "WnTFxezsfUh6LwLDcMijUEfliIz2";
    },
  },

  methods: {
    async getUserData() {
      if (this.user) {
        const userDoc = await getDoc(doc(db, "users", this.user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          this.isApproved = userData.isApproved;
          this.isEmailVerified = userData.isEmailVerified;
        }
      }
    },

    async resendVerification() {
      this.resendLoading = true;
      try {
        await sendEmailVerification(this.user);
        this.$toast.success("Verification email sent");
      } catch (error) {
        this.$toast.error("Something went wrong, please try again later");
      }
      this.resendLoading = false;
    },

    async requestAccess() {
      this.requestLoading = true;
      try {
        await updateDoc(doc(db, "users", this.user.uid), {
          isEmailVerified: true,
        });
        this.$toast.success("Access requested");
      } catch (error) {
        this.$toast.error("Something went wrong, please try again later");
      }
      this.requestLoading = false;
    },
  },
};
</script>
