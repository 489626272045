<template>
  <v-main>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card>
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>Sign up as teacher</v-toolbar-title>
            </v-toolbar>

            <v-card-text>
              <v-form ref="form" v-model="formIsValid">
                <v-row dense>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      prepend-icon="mdi-account"
                      label="First Name"
                      name="firstName"
                      v-model="firstName"
                      :rules="[rules.required]"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Last Name"
                      name="lastName"
                      v-model="lastName"
                      :rules="[rules.required]"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      prepend-icon="mdi-email-outline"
                      label="Email"
                      name="email"
                      v-model="email"
                      :rules="[rules.required, rules.email]"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      prepend-icon="mdi-lock-open-outline"
                      label="Password"
                      name="password"
                      v-model="password"
                      :type="showPassword ? 'text' : 'password'"
                      :append-icon="!showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                      :rules="[rules.required, rules.validPassword]"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      prepend-icon="mdi-lock-open-outline"
                      label="Confirm Password"
                      name="confirmPassword"
                      v-model="confirmPassword"
                      :type="showPassword ? 'text' : 'password'"
                      :append-icon="!showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                      :rules="[rules.required, rules.match]"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <p class="ml-3">
                Have an account?
                <router-link
                  to="/signin"
                  class="font-weight-bold"
                  style="text-decoration: none"
                >
                  Sign in
                </router-link>
              </p>

              <v-spacer></v-spacer>
              <v-btn
                :disabled="!formIsValid || loadingSignup"
                :loading="loadingSignup"
                color="primary"
                @click="signup"
              >
                <v-icon left>mdi-login-variant</v-icon>Sign up
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="overlay" persistent max-width="600px">
      <v-card>
        <v-card-title>{{ overlayTitle }}</v-card-title>
        <v-card-text v-if="overlaySuccess">{{ overlayText }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="overlaySuccess"
            color="primary"
            class="ml-1"
            @click="overlayOk"
          >
            Ok
          </v-btn>
          <v-btn v-else color="primary" class="ml-1" @click="overlay = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import { auth, db } from "../api/firebase";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";

export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",

      rules: {
        required: (v) => !!v || "Required",
        email: (v) =>
          /^[\w-.]+@([\w-]+\.)+[\w-]{2,7}$/.test(v) || "Email must be valid",
        match: (v) => v === this.password || "Password not match",
        validPassword: (v) =>
          /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(v) ||
          "Password must have at least 8 chars with 1 uppercase letter, 1 lowercase letter and 1 number",
      },

      formIsValid: true,
      showPassword: false,
      loadingSignup: false,

      overlay: false,
      overlaySuccess: false,
      overlayTitle: "",
      overlayText: "",
    };
  },

  methods: {
    async signup() {
      this.loadingSignup = true;

      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          this.email,
          this.password
        );
        const user = userCredential.user;
        await setDoc(doc(db, "users", user.uid), {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          isApproved: false,
          isEmailVerified: false,
        }).catch(async () => {
          await user.delete();
        });
        await updateProfile(user, {
          displayName: this.firstName + " " + this.lastName,
        });
        await sendEmailVerification(user);
        this.overlay = true;
        this.overlaySuccess = true;
        this.overlayTitle = "Thank You!";
        this.overlayText =
          "A verification email has been sent. Please verify your email so STEPP team can approve your account.";
      } catch (error) {
        this.overlay = true;
        this.overlayTitle = "Something went wrong! Please try again later.";
      }

      this.loadingSignup = false;
    },

    overlayOk() {
      this.overlay = false;
      this.$router.push("/");
    },
  },
};
</script>
