import Vue from "vue";
import VueRouter from "vue-router";
import { auth } from "../api/firebase";
import Home from "../views/Home.vue";
import Team from "../views/Team.vue";
import Publication from "../views/Publication.vue";
import Tutorial from "../views/Tutorial.vue";
import Application from "../views/Application.vue";
import Account from "../views/Account.vue";
import Admin from "../views/Dashboard/Admin";
import Signin from "../views/Signin.vue";
import Signup from "../views/Signup.vue";
import ForgotPassword from "../views/ForgotPassword.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/team",
    name: "team",
    component: Team,
  },
  {
    path: "/publication",
    name: "publication",
    component: Publication,
  },
  {
    path: "/tutorial",
    name: "tutorial",
    component: Tutorial,
  },
  {
    path: "/application",
    name: "application",
    component: Application,
  },
  {
    path: "/account",
    name: "account",
    component: Account,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin",
    name: "admin",
    component: Admin,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: "/signin",
    name: "signin",
    component: Signin,
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: Signup,
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: ForgotPassword,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const user = auth.currentUser;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!user) {
      next("/signin");
    } else if (to.matched.some((record) => record.meta.requiresAdmin)) {
      if (user.uid === "WnTFxezsfUh6LwLDcMijUEfliIz2") {
        next();
      } else {
        next("/not-found");
      }
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresGuest)) {
    if (user) {
      next("/");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
