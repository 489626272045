<template>
  <v-container>
    <v-card class="mx-auto" max-width="1200px">
      <v-tabs vertical>
        <v-tab>
          <v-icon left>mdi-school-outline</v-icon>Account Profile
          <v-spacer></v-spacer>
        </v-tab>
        <v-tab>
          <v-icon left>mdi-account-outline</v-icon>Edit Profile
          <v-spacer></v-spacer>
        </v-tab>
        <v-tab>
          <v-icon left>mdi-teach</v-icon>Change Password
          <v-spacer></v-spacer>
        </v-tab>

        <v-tab-item>
          <v-card max-width="1200px" class="mx-auto" flat>
            <v-card color="primary" class="mx-auto white--text">
              <v-card-title>Account Profile</v-card-title>
            </v-card>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="headline">
                  {{ firstName }} {{ lastName }}
                </v-list-item-title>

                <v-list-item-title class="subtitle-1">
                  Teacher
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Email: {{ email }}
                  <v-tooltip v-if="user.emailVerified" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="success" v-bind="attrs" v-on="on">
                        mdi-check-circle-outline
                      </v-icon>
                    </template>
                    <span>Verified</span>
                  </v-tooltip>
                  <v-tooltip v-else bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="warning" v-bind="attrs" v-on="on">
                        mdi-alert-circle-outline
                      </v-icon>
                    </template>
                    <span>Unverified</span>
                  </v-tooltip>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Participation ID: {{ user.uid }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Access to dashboard:
                  {{ isApproved ? "Approved" : "Not Granted" }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card max-width="1200px" class="mx-auto">
            <v-card color="primary" class="mx-auto white--text">
              <v-card-title>Edit Profile</v-card-title>
            </v-card>

            <v-card-text>
              <v-form
                ref="formEdit"
                v-model="formEditIsValid"
                style="max-width: 400px"
              >
                <v-row dense>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      prepend-icon="mdi-account"
                      label="First Name"
                      name="firstName"
                      v-model="firstName"
                      :rules="[rules.required]"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Last Name"
                      name="lastName"
                      v-model="lastName"
                      :rules="[rules.required]"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      label="Email"
                      v-model="email"
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>

              <v-btn
                :disabled="!formEditIsValid || loadingEdit"
                :loading="loadingEdit"
                @click="submitProfile"
                color="primary"
              >
                Update profile
              </v-btn>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card max-width="1200px" class="mx-auto">
            <v-card color="primary" class="mx-auto white--text">
              <v-card-title>Change Password</v-card-title>
            </v-card>

            <v-card-text>
              <v-form ref="form" v-model="formIsValid" style="max-width: 400px">
                <v-text-field
                  prepend-icon="mdi-lock-open-outline"
                  label="Old password"
                  name="password"
                  v-model="oldPassword"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  :rules="[rules.required, rules.validPassword]"
                  required
                ></v-text-field>

                <v-text-field
                  prepend-icon="mdi-lock-open-outline"
                  label="New password"
                  name="newPassword"
                  v-model="newPassword"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  :rules="[rules.required, rules.validPassword]"
                  required
                ></v-text-field>

                <v-text-field
                  prepend-icon="mdi-lock-open-outline"
                  label="Confirm new password"
                  name="confirmPassword"
                  v-model="confirmPassword"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  :rules="[rules.required, rules.match]"
                  required
                ></v-text-field>

                <p>
                  <router-link
                    to="/forgot-password"
                    class="font-weight-bold"
                    style="text-decoration: none"
                  >
                    Forgot password?
                  </router-link>
                </p>
              </v-form>

              <v-btn
                :disabled="!formIsValid || loadingChange"
                :loading="loadingChange"
                @click="submitPassword"
                color="primary"
              >
                Update password
              </v-btn>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
import { auth, db } from "../api/firebase";
import {
  updateProfile,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";

export default {
  data() {
    return {
      user: auth.currentUser,
      firstName: "",
      lastName: "",
      email: "",
      isApproved: false,

      rules: {
        required: (v) => !!v || "Required",
        match: (v) => v === this.newPassword || "Password not match",
        validPassword: (v) =>
          /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(v) ||
          "Password must have at least 8 chars with 1 uppercase letter, 1 lowercase letter and 1 number",
      },

      formEditIsValid: true,
      loadingEdit: false,

      oldPassword: "",
      newPassword: "",
      confirmPassword: "",

      formIsValid: true,
      loadingChange: false,
      showPassword: false,
    };
  },

  async created() {
    if (this.user) {
      const userDoc = await getDoc(doc(db, "users", this.user.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        this.firstName = userData.firstName;
        this.lastName = userData.lastName;
        this.isApproved = userData.isApproved;
        this.email = userData.email;
      }
    }
  },

  methods: {
    async submitProfile() {
      this.loadingEdit = true;
      try {
        await updateDoc(doc(db, "users", this.user.uid), {
          firstName: this.firstName,
          lastName: this.lastName,
        });
        await updateProfile(this.user, {
          displayName: this.firstName + " " + this.lastName,
        });
        this.$toast.success("Profile updated successfully");
      } catch (error) {
        this.$toast.error("Something went wrong, please try again later");
      }
      this.loadingEdit = false;
    },
    async submitPassword() {
      this.loadingChange = true;
      try {
        const credential = EmailAuthProvider.credential(
          this.user.email,
          this.oldPassword
        );
        await reauthenticateWithCredential(this.user, credential);
        await updatePassword(this.user, this.newPassword);
      } catch (error) {
        this.$toast.error("Wrong old password");
      }
      this.loadingChange = false;
      this.$refs.form.reset();
    },
  },
};
</script>
