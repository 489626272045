var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"1200px","flat":""}},[_c('v-card',{staticClass:"mx-auto white--text",attrs:{"color":"primary"}},[_c('v-card-title',[_vm._v("Manage Teacher Access")])],1),_c('v-tabs',[_c('v-tab',[_vm._v("Approved teachers")]),_c('v-tab',[_c('v-badge',{attrs:{"color":"red","content":_vm.pendingTeachers.length,"value":_vm.pendingTeachers.length}},[_vm._v(" Pending Requests ")])],1),_c('v-tab',[_vm._v("Denied list")]),_c('v-tab-item',[_c('v-card',[_c('v-card-title',[_vm._v(" Approved Teachers "),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Search","append-icon":"mdi-magnify","hide-details":"","clearable":"","outlined":"","dense":"","solo":"","flat":""},model:{value:(_vm.searchTeacher),callback:function ($$v) {_vm.searchTeacher=$$v},expression:"searchTeacher"}}),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.getTeachers()}}},'v-icon',attrs,false),on),[_vm._v(" mdi-refresh ")])]}}])},[_c('span',[_vm._v("Refresh")])])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headersTeacher,"items":_vm.approvedTeachers,"search":_vm.searchTeacher,"sort-by":['fullName'],"item-key":"id","items-per-page":-1},scopedSlots:_vm._u([{key:"item.isApproved",fn:function(ref){
var item = ref.item;
return [(item.isApproved)?_c('v-chip',{staticClass:"white--text",attrs:{"color":_vm.getColor(item.isApproved)}},[_vm._v(" Yes ")]):_c('v-chip',{staticClass:"white--text",attrs:{"color":_vm.getColor(item.isApproved)}},[_vm._v(" No ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){return _vm.deleteTeacherDialogShow(item)}}},[_vm._v(" Remove access ")])]}}])})],1)],1)],1),_c('v-tab-item',[_c('v-card',[_c('v-card-title',[_vm._v(" Pending Requests "),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Search","append-icon":"mdi-magnify","hide-details":"","clearable":"","outlined":"","dense":"","solo":"","flat":""},model:{value:(_vm.searchRequest),callback:function ($$v) {_vm.searchRequest=$$v},expression:"searchRequest"}}),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.getTeachers()}}},'v-icon',attrs,false),on),[_vm._v(" mdi-refresh ")])]}}])},[_c('span',[_vm._v("Refresh")])])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headersTeacher,"items":_vm.pendingTeachers,"search":_vm.searchRequest,"sort-by":['fullName'],"item-key":"id","items-per-page":-1},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.acceptTeacherDialogShow(item)}}},[_vm._v(" Approve ")]),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){return _vm.deleteTeacherDialogShow(item)}}},[_vm._v(" Deny ")])]}}])})],1)],1)],1),_c('v-tab-item',[_c('v-card',[_c('v-card-title',[_vm._v(" Denied List "),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Search","append-icon":"mdi-magnify","hide-details":"","clearable":"","outlined":"","dense":"","solo":"","flat":""},model:{value:(_vm.searchDenied),callback:function ($$v) {_vm.searchDenied=$$v},expression:"searchDenied"}}),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.getTeachers()}}},'v-icon',attrs,false),on),[_vm._v(" mdi-refresh ")])]}}])},[_c('span',[_vm._v("Refresh")])])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headersTeacher,"items":_vm.deniedTeachers,"search":_vm.searchDenied,"sort-by":['fullName'],"item-key":"id","items-per-page":-1},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.acceptTeacherDialogShow(item)}}},[_vm._v(" Restore ")])]}}])})],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.teacherAcceptDialog),callback:function ($$v) {_vm.teacherAcceptDialog=$$v},expression:"teacherAcceptDialog"}},[_c('v-card',[_c('v-card-title',[(_vm.acceptForm.isApproved === false)?_c('span',{staticClass:"headline"},[_vm._v(" Approve "+_vm._s(_vm.acceptForm.fullName)+" request? ")]):_c('span',{staticClass:"headline"},[_vm._v(" Restore "+_vm._s(_vm.acceptForm.fullName)+"'s access? ")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.teacherAcceptDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"ml-1",attrs:{"loading":_vm.loadingAccept,"disabled":_vm.loadingAccept,"color":"primary","text":""},on:{"click":_vm.acceptTeacher}},[_vm._v(" "+_vm._s(_vm.acceptForm.isApproved === false ? "Approve" : "Restore")+" ")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.teacherDeleteDialog),callback:function ($$v) {_vm.teacherDeleteDialog=$$v},expression:"teacherDeleteDialog"}},[_c('v-card',[_c('v-card-title',[(_vm.deleteForm.isApproved === false)?_c('span',{staticClass:"headline"},[_vm._v(" Deny "+_vm._s(_vm.deleteForm.fullName)+" request? ")]):_c('span',{staticClass:"headline"},[_vm._v(" Remove "+_vm._s(_vm.deleteForm.fullName)+"'s access? ")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.teacherDeleteDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"ml-1",attrs:{"loading":_vm.loadingDelete,"disabled":_vm.loadingDelete,"color":"error","text":""},on:{"click":_vm.deleteTeacher}},[_vm._v(" "+_vm._s(_vm.deleteForm.isApproved === false ? "Deny" : "Remove")+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }